import api from '@/api/jai.js';
import _ from 'lodash';
import showSwal from "@/mixins/showSwal.js";

export default {
	namespaced: true,
	state: function() {
		return {
			widgets: [],
			currentWidget: null,	
		}
	},
	mutations: {
		setWidget: function(state, data) {
			state.currentWidget = data;
		},
		setErrorMessage: function(state, text) {
			showSwal.methods.showSwal({
				type:"error",
				message: text,
				icon: 'error'
			});
		},
	},
	actions: {
		askQuestion: async function(state, args) {
			var data = await api.askQuestion(args);
			if(_.has(data, 'error')) {
				state.commit('setErrorMessage', data.error);
			} else {
				state.commit('setWidget', data);
			}
		},

	},
	getters: {
		currentWidget: function(state) {
			return state.currentWidget;
		},
	}
}