<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
import logo from "@/assets/img/logo_jai.jpg";
//import logo from "@/assets/img/logo-ct-dark.png";
//import logoWhite from "@/assets/img/logo-ct.png";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const darkMode = computed(() => store.state.darkMode);
const setSidebarType = (type) => store.commit("sidebarType", type);

watch(() => darkMode.value, () => { 
    if(darkMode.value) {
      setSidebarType("bg-default");
      activateDarkMode();   
    } else {
      setSidebarType("bg-white");
      deactivateDarkMode();
    }
}, { immediate: true });

const switchDarkMode = () => {
  if (store.state.darkMode) {
    store.state.darkMode = false;
    window.localStorage.setItem("darkMode", store.state.darkMode);
  //  setSidebarType("bg-white");
  //  deactivateDarkMode();
    return;
  } else {
    store.state.darkMode = true;
    window.localStorage.setItem("darkMode", store.state.darkMode);
  //  setSidebarType("bg-default");
  //  activateDarkMode();
  }
};
</script>
<template>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-dark'}`"
  />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'}    
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="logo"
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <!--i class="ni-lg ni ni-atom"></i-->
        <span class="text-lg ms-2 font-weight-bold">Joseph JAI</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <div class="mt-1 mb-1 d-flex p-2">
          <h6 class="mb-0" :class="isRTL ? 'ms-2' : ''">Light / Dark</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              :checked="store.state.darkMode"
              @click="switchDarkMode"
            />
          </div>
        </div>



    <sidenav-list />
  </aside>
</template>
